const data: DevToolsHTMLLanguageCategory[] = [
  {
    name: 'JavaScript',
    examples: [
      {
        name: 'Cypress',
        uses: ['@axe-devtools/cypress'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/cypress',
        docsURL: '/devtools-html/4.0.0/en/node-cy-overview',
        icon: '/images/cypress.svg',
      },
      {
        name: 'Playwright',
        uses: ['@axe-devtools/playwright'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/playwright',
        docsURL: '/devtools-html/4.0.0/en/node-pl-overview',
        icon: '/images/playwright.svg',
      },
      {
        name: 'Playwright with Mocha',
        uses: ['@axe-devtools/playwright'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/playwright-mocha',
        docsURL: '/devtools-html/4.0.0/en/node-pl-overview',
        icon: '/images/playwright.svg',
      },
      {
        name: 'Puppeteer',
        uses: ['@axe-devtools/puppeteer'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/puppeteer',
        docsURL: '/devtools-html/4.0.0/en/node-pu-overview',
        icon: '/images/puppeteer.svg',
      },
      {
        name: 'Puppeteer with Mocha',
        uses: ['@axe-devtools/puppeteer'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/puppeteer-mocha',
        docsURL: '/devtools-html/4.0.0/en/node-pu-overview',
        icon: '/images/puppeteer.svg',
      },
      {
        name: 'React with Jest and Enzyme',
        uses: ['@axe-devtools/browser'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/jest-enzyme',
        docsURL: '/devtools-html/4.0.0/en/node-br-overview',
        icon: '/images/react.svg',
      },
      {
        name: 'React with Jest and ReactDOM',
        uses: ['@axe-devtools/browser'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/jest-reactdom',
        docsURL: '/devtools-html/4.0.0/en/node-br-overview',
        icon: '/images/react.svg',
      },
      {
        name: 'React, Jest, and React Testing Library',
        uses: ['@axe-devtools/browser'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/jest-react-testing-lib',
        docsURL: '/devtools-html/4.0.0/en/node-br-overview',
        icon: '/images/react.svg',
      },
      {
        name: 'TestCafe',
        uses: ['@axe-devtools/script-builder'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/axeTestcafe',
        docsURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/blob/main/Node/axeTestcafe/README.md',
        icon: '/images/testcafe-logo.svg',
      },
      {
        name: 'WebDriverIO',
        uses: ['@axe-devtools/webdriverio'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/webdriverio',
        docsURL: '/devtools-html/4.0.0/en/node-wi-overview',
        icon: '/images/webdriverio.svg',
      },
      {
        name: 'WebDriverJS',
        uses: ['@axe-devtools/webdriverjs'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/webdriver.js_vanilla',
        docsURL: '/devtools-html/4.0.0/en/node-wj-overview',
        icon: '/images/selenium.svg',
      },
      {
        name: 'WebDriverJS with Mocha',
        uses: ['@axe-devtools/webdriverjs'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Node/webdriverjs-mocha',
        docsURL: '/devtools-html/4.0.0/en/node-wj-overview',
        icon: '/images/selenium.svg',
      },
    ],
  },
  {
    name: 'C#',
    examples: [
      {
        name: 'MSTest',
        uses: ['Deque.AxeDevtools.Selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/C%23/MSTest',
        docsURL: '/devtools-html/4.0.0/en/cs-overview',
        icon: '/images/microsoft.svg',
      },
      {
        name: 'NUnit',
        uses: ['Deque.AxeDevtools.Selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/C%23/NUnit',
        docsURL: '/devtools-html/4.0.0/en/cs-overview',
        icon: '/images/nunit.svg',
      },
      {
        name: 'Selenium',
        uses: ['Deque.AxeDevtools.Selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/C%23/Selenium',
        docsURL: '/devtools-html/4.0.0/en/cs-overview',
        icon: '/images/selenium.svg',
      },
    ],
  },
  {
    name: 'CLI',
    examples: [
      {
        name: 'Spec Mode',
        uses: ['@axe-devtools/cli'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/CLI',
        docsURL: '/devtools-html/4.0.0/en/cli-home',
        icon: '/images/console.svg',
      },
    ],
  },
  {
    name: 'Java',
    examples: [
      {
        name: 'Cucumber PicoContainer',
        uses: ['com.deque.html.axedevtools.cucumber'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/cucumber-pico',
        docsURL: '/devtools-html/4.0.0/en/java-test-cucumber',
        icon: '/images/pico-logo.png',
      },
      {
        name: 'Cucumber Spring',
        uses: ['com.deque.html.axedevtools.cucumber'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/cucumber-spring',
        docsURL: '/devtools-html/4.0.0/en/java-test-cucumber',
        icon: '/images/spring-icon.svg',
      },
      {
        name: 'Gradle JUnit',
        uses: ['com.deque.html.axedevtools.selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/gradle-junit',
        docsURL: '/devtools-html/4.0.0/en/java-overview',
        icon: '/images/junit.svg',
      },
      {
        name: 'Gradle TestNG',
        uses: ['com.deque.html.axedevtools.selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/gradle-testng',
        docsURL: '/devtools-html/4.0.0/en/java-overview',
        icon: '/images/testng.svg',
      },
      {
        name: 'Hamcrest',
        uses: ['com.deque.html.axedevtools.selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/hamcrest',
        docsURL: '/devtools-html/4.0.0/en/java-test-hamcrest',
        icon: '/images/hamcrest.jpg',
      },
      {
        name: 'Selenium',
        uses: ['com.deque.html.axedevtools.selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/java-selenium',
        docsURL: '/devtools-html/4.0.0/en/java-test-selenium',
        icon: '/images/selenium.svg',
      },
      {
        name: 'Playwright',
        uses: ['com.deque.html.maven.axedevtools.playwright'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/playwright',
        docsURL: '/devtools-html/4.0.0/en/java-test-playwright',
        icon: '/images/playwright.svg',
      },
      {
        name: 'TestNG',
        uses: ['com.deque.html.axedevtools.selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Java/testNG',
        docsURL: '/devtools-html/4.0.0/en/java-overview',
        icon: '/images/testng.svg',
      },
    ],
  },
  {
    name: 'Python',
    examples: [
      {
        name: 'Behave',
        uses: ['axe_devtools_behave'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Python/behave',
        docsURL: '/devtools-html/4.0.0/en/py-api-behave',
        icon: '/images/behave.jpg',
      },
      {
        name: 'PyTest',
        uses: ['axe_devtools_selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Python/pytest',
        docsURL: '/devtools-html/4.0.0/en/py-usage-overview',
        icon: '/images/pytest.svg',
      },
      {
        name: 'Selenium',
        uses: ['axe_devtools_selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Python/python_selenium',
        docsURL: '/devtools-html/4.0.0/en/py-api-selenium',
        icon: '/images/selenium.svg',
      },
      {
        name: 'Robot',
        uses: ['axe_devtools_robot'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Python/robot',
        docsURL: '/devtools-html/4.0.0/en/py-api-robot',
        icon: '/images/robot-framework.png',
      },
      {
        name: 'unittest',
        uses: ['axe_devtools_unittest'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Python/unittest',
        docsURL: '/devtools-html/4.0.0/en/py-api-unittest',
        icon: '/images/python.svg',
      },
    ],
  },
  {
    name: 'Ruby',
    examples: [
      {
        name: 'Capybara with RSpec',
        uses: ['axe-capybara'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Ruby/capybara-rspec',
        docsURL: '/devtools-html/4.0.0/en/rb-capybara',
        icon: '/images/capybara.png',
      },
      {
        name: 'Cucumber',
        uses: ['axe-cucumber-steps'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Ruby/selenium-cucumber',
        docsURL: '/devtools-html/4.0.0/en/rb-cucumber',
        icon: '/images/cucumber.svg',
      },
      {
        name: 'Selenium with RSpec',
        uses: ['axe-selenium'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Ruby/selenium-rspec',
        docsURL: '/devtools-html/4.0.0/en/rb-selenium',
        icon: '/images/selenium.svg',
      },
      {
        name: 'Watir with RSpec',
        uses: ['axe-watir'],
        exampleURL:
          'https://github.com/dequelabs/axe-devtools-html-api-examples/tree/main/Ruby/watir-rspec',
        docsURL: '/devtools-html/4.0.0/en/rb-watir',
        icon: '/images/watir.png',
      },
    ],
  },
]

export default data
