import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import languageCategories from '../config/devtools-for-web-examples'
import {
  Card,
  CardContent,
  CardHeader,
  Link,
  Tab,
  TabPanel,
  Tabs,
} from '@deque/cauldron-react'
import '@deque/cauldron-styles'
import './devtools-for-web-examples.scss'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import createDocumentTitle from '../lib/document-title'
import { navigate } from 'gatsby'
import { exampleClick } from '../lib/analytics'
import Logo from '../components/Header/Logo'
import SiteSearch from '../components/Search/SiteSearch'
import ContactUs from '../components/Header/ContactUs'

export default function () {
  const { title: siteTitle } = useSiteMetadata()
  const pageTitle = 'axe DevTools® for Web Examples'

  const tabs: React.ReactElement[] = []
  const tabPanels: React.ReactElement[] = []

  for (const languageCategory of languageCategories) {
    const { name: language, examples } = languageCategory
    const tabRef: React.RefObject<HTMLDivElement> = React.useRef(null)
    const tab = (
      <Tab key={tabs.length} target={tabRef}>
        {language}
      </Tab>
    )
    const tabPanel = (
      <TabPanel key={tabs.length} ref={tabRef}>
        <div className="Cards">
          {examples.map((example, i) => (
            <Example key={i} data={{ ...example, language }} />
          ))}
        </div>
      </TabPanel>
    )
    tabs.push(tab)
    tabPanels.push(tabPanel)
  }

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(siteTitle, pageTitle)}</title>
      </Helmet>
      <div className="DevToolsHTMLExamples">
        <Header centerAlign>
          <div className="cluster--left">
            <Logo />
          </div>
          <div className="cluster--right">
            <SiteSearch />
            <ContactUs />
          </div>
        </Header>
        <div className="Content center-align">
          <div>
            <h1>{pageTitle}</h1>
            <Tabs aria-label="axe DevTools® for Web examples by language">
              {tabs}
            </Tabs>
            {tabPanels}
          </div>
        </div>
        <Footer centerAlign />
      </div>
    </>
  )
}

interface ExampleProps {
  data: DevToolsHTMLExample
}

function Example(props: ExampleProps) {
  const { data } = props
  const { language, name, icon, uses, exampleURL, docsURL } = data

  const handleExampleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    await exampleClick({ language, name, destination: 'github' })
    navigate(exampleURL)
  }

  const handleDocsClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    await exampleClick({ language, name, destination: 'docs' })
    navigate(docsURL)
  }

  return (
    <Card variant="simple">
      <CardHeader>
        <h2>{name}</h2>
      </CardHeader>
      <CardContent>
        <div className="Logo">
          <img src={icon} alt={`${name} logo`} />
        </div>
        <h3>Uses:</h3>
        <ul className="Packages">
          {uses.map((name, i) => {
            return (
              <li key={i} className="Package">
                {name}
              </li>
            )
          })}
        </ul>
        <div className="Buttons">
          {exampleURL && (
            <Link
              variant="button"
              href={exampleURL}
              onClick={handleExampleClick}
            >
              <span>Code Example</span>
              <i className="fas fa-external-link-alt"></i>
              <span className="Offscreen">(links to an external site)</span>
            </Link>
          )}
          {docsURL && (
            <Link
              className="Button--secondary"
              variant="button"
              href={docsURL}
              onClick={handleDocsClick}
            >
              <span>Documentation</span>
            </Link>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
